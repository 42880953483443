<template>
    <div class="order-list">
	    
	    <div class="balance">
		    <div>金币余额: {{userInfo ? userInfo.balance.coin ? parseFloat(userInfo.balance.coin).toFixed(0) : 0 : 0}}</div>
	    </div>
	    <div class="charge-container">
		    <div class="price-container">
			    <div>
				    <h3>价目表</h3>
				    <p>一张图片: {{ picturePrice }}金币</p>
				    <p>一篇文章: {{ articlePrice }}金币</p>
			    </div>
		    </div>
		    <div>
			    <div>
				    <div class="charge-type">
					    <div :class="{active:payType==='wechatPay'}" class="type-box" @click="payType='wechatPay'">
						    <img :src="require('@/assets/img/wechatPay.png')"/>
						    <p>微信</p>
					    </div>
<!--					    <div :class="{active:payType==='alipay'}"  class="type-box" @click="payType='alipay'">-->
<!--						    <img :src="require('@/assets/img/alipay.png')"/>-->
<!--						    <p>支付宝</p>-->
<!--					    </div>-->
				    </div>
				    <div class="charge-input">
					    <el-input v-model="chargeAmount" placeholder="输入人民币金额"></el-input>
					    <div style="height: 1rem"><p>={{chargeAmountCoin}}金币</p></div>
					    <el-button type="primary" class='charge-button' @click="charge">立即充值</el-button>
				    </div>
			    </div>
		    </div>
		    <div v-show="chargeQrCode">
			    <vue-qr
				    :text="chargeQrCode"
				    :logoSrc="require('@/assets/img/'+payType+'.png')"
				    :size="180"
				    :logoScale="0.2">
			    </vue-qr>
		    </div>
	    </div>
	    
	    
	    
	    <div class="charge-container">
		    <div style="width: 100%">
				    <h2 style="text-align: center">卡密兑换</h2>
			    <div>
				    <div class="charge-input">
					    <el-input style="width: 90%" type="textarea" rows="4rem" v-model="activationCode" placeholder="输入卡密,一行一个"></el-input>
					    <el-button style="margin-top:2rem" type="primary" class='charge-button' @click="active">立即兑换</el-button>
				    </div>
			    </div>
		    </div>
	    </div>
    </div>
</template>

<script>
import NormalTable from "@/components/NormalTable/index.vue";
import {getCookie} from "@/utils/auth";

export default {
    data() {
        return {
            total: 0,
            otherMap: {totalAccount:0},
            dateRange: [],
            form: {
                status: ''
            },
            
	        
	        payType: 'wechatPay',
	        chargeAmount: '',
	        chargeAmountCoin:0,
	        chargeQrCode: '',
	        chargeOrderId: 0,
	        userInfo: null,
	        picturePrice: 0,
	        articlePrice: 0,
	        coinPrice: 10,
	        
	        activationCode:""
        };
    },

    components: {
        NormalTable,
	    VueQr: () => import('vue-qr')
    },

    computed: {},

    mounted() {
	    this.$http.post('/client/common/getPublicConfig', {}).then(res => {
		    if (res.code === 0) {
			    res.data.list.forEach(item => {
				    if (item.key === 'picturePrice') {
					    this.picturePrice = item.value
				    } else if (item.key === 'articlePrice') {
					    this.articlePrice = item.value
				    } else if (item.key === 'coinPrice') {
					    this.coinPrice = item.value
				    }
			    })
		    }
	    })
	    this.getUserInfo()
    },

    watch: {
	    chargeAmount(val) {
		    this.changeAmount()
	    },
    },

    methods: {
			active() {
				if (!this.activationCode) {
					this.$message({
						type: "error",
						message: "请输入卡密",
					});
					return
				}
				this.$http.post('/client/activationCode/active', {ids: this.activationCode}).then(res => {
					if (res.code === 0) {
						this.$message({
							type: "success",
							message: "激活成功",
						});
						this.getUserInfo()
					} else {
						this.$message({
							type: "error",
							message: res.msg,
						});
					}
				})
			},
	    changeAmount() {
		    //只能输入数字或者小数
		    this.chargeAmount = this.chargeAmount.replace(/[^\d.]/g, '')
				this.chargeAmountCoin = this.chargeAmount * this.coinPrice
	    },
	    getUserInfo() {
		    let path = '/client/user/info'
		    this.$http.post(path, {}).then(res => {
			    if (res.code === 0) {
						this.userInfo = res.data.userInfo
			    }
		    })
	    },
	    charge() {
				if (!this.chargeAmount) {
					this.$message({
						type: "error",
						message: "请输入充值金额",
					});
					return
				}
				let that = this
		    this.$http.post("/client/wallet/createChargeOrder",
			    {payAmount: this.chargeAmount, payType: this.payType, orderType:'charge'}
		    ).then((res) => {
			    this.chargeQrCode = res.data.qrCodeUrl
			    this.chargeOrderId = res.data.orderId
			    this.$message({
				    type: "success",
				    message: "创建订单成功",
			    });
			    that.getOrderStatus()
		    });
				
	    },
	    getOrderStatus() {
		    setTimeout(() => {
			    this.$http.post("/client/wallet/getOrderStatus",
				    {id: this.chargeOrderId}
			    ).then((res) => {
				    if (res.data.orderInfo && res.data.orderInfo.status === 'success') {
					    this.chargeQrCode = ''
					    this.chargeOrderId = 0
					    this.getUserInfo()
					    this.$message({
						    type: "success",
						    message: "支付成功",
					    });
					    window.location.reload()
				    } else {
					    this.getOrderStatus()
				    }
			    });
		    }, 3000)
	    }
    }
};
</script>
<style lang="scss" scoped>
.balance {
	font-size: 1.5rem;
	font-weight: bold;
	display: flex;
	margin-bottom: 1rem;
	justify-content: center;
	align-items: center;
	text-align: center;
	margin-top: 3rem;
}

.charge-container {
	max-width: 500px;
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	justify-content: space-evenly;
	padding: 1rem 0 2rem 0;
	border: solid 1px #ebeef5;
	margin: 2rem auto 1rem auto;
	border-radius: 1rem;
	.price-container {
		text-align: center;
		line-height: 2rem;
	}
	
	.charge-input {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		margin-top:1rem;
		.charge-button {
			margin-top: .5rem;
			width: 5rem;
		}
	}
	.charge-type {
		display: flex;
		justify-content: space-around;
		.type-box {
			width: 5rem;
			text-align: center;
			cursor: pointer;
			padding: 0.3rem 0;
			box-sizing: border-box;
			border: solid 1px rgba(0, 0, 0, 0);
		}
		.active {
			border: solid 1px #409eff;
			border-radius: 5px;
		}
		img {
			width: 3rem;
		}
	}
}
</style>